.animation-text {
  animation-name: myanimation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes myanimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
